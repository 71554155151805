<template>
  <v-container
    id="nomenclature-edit"
    fluid
    tag="section"
  >
    <v-row justify="center">
      <v-col
        cols="12"
        md="12"
      >
        <material-card
          color="primary"
          icon="mdi-account-outline"
        >
          <template #title>
            {{ isEditMode ? 'Edit Product' : 'New Product' }}
          </template>

          <v-tabs vertical>
            <v-tab>
              <v-icon left>
                mdi-account
              </v-icon>
              Main
            </v-tab>
            <v-tab>
              <v-icon left>
                mdi-lock
              </v-icon>
              Stages
            </v-tab>

            <v-tab-item>
              <v-form>
                <v-container class="py-0">
                  <v-row>
                    <v-col
                      cols="12"
                      md="11"
                      sm="11"
                    >
                      <v-text-field
                        v-model="item.name"
                        color="purple"
                        label="Name *"
                      />
                    </v-col>

                    <v-col
                      cols="12"
                      md="1"
                      sm="1"
                    >
                      <v-switch
                        v-model="item.is_active"
                        color="purple"
                        label="Active"
                      />
                    </v-col>

                    <v-col
                      cols="12"
                      md="6"
                    >
                      <v-text-field
                        v-model="item.growing_percentage"
                        type="number"
                        color="purple"
                        label="Growing Percentage"
                      />
                    </v-col>

                    <v-col
                      cols="12"
                      md="6"
                    >
                      <v-text-field
                        v-model="item.max_growing_age"
                        type="number"
                        color="purple"
                        label="Maximal Growing Age(days)"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-tab-item>
            <v-tab-item>
              <stages-tab :product-id="productId" />
            </v-tab-item>
          </v-tabs>
          <v-container class="py-0">
            <v-row>
              <v-col
                cols="12"
                class="text-right"
              >
                <v-btn
                  color="primary"
                  min-width="150"
                  @click="submit"
                >
                  {{ isEditMode ? 'Save Product' : 'Create Product' }}
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapActions } from 'vuex'
  import StagesTab from '@/components/Nomenclature/Edit/Tabs/StagesTab'

  export default {
    name: 'NomenclatureItemEditForm',
    components: {
      StagesTab,
    },

    data: () => ({
      loading: false,
      item: {
        name: '',
        is_active: 1,
        growing_percentage: 30,
        max_growing_age: null,
      },
    }),
    computed: {
      isEditMode () {
        return !!this.$route.params.id
      },
      productId () {
        if (!this.$route.params.id) {
          return 0
        }
        return parseInt(this.$route.params.id)
      },
    },
    mounted () {
      if (this.isEditMode) {
        this.loadItem({ product_id: this.$route.params.id })
          .then(result => {
            this.item.name = result.data.name
            this.item.is_active = result.data.is_active
            this.item.growing_percentage = result.data.growing_percentage
            this.item.max_growing_age = result.data.max_growing_age
          })
      }
    },
    methods: {
      ...mapActions({
        loadItem: 'nomenclature/loadItem',
        create: 'nomenclature/create',
        update: 'nomenclature/update',
      }),
      submit () {
        this.loading = true
        if (this.isEditMode) {
          this.update({
            product_id: this.$route.params.id,
            ...this.item,
          })
            .finally(() => {
              this.loading = false
            })
        } else {
          this.create(this.item)
            .finally(() => {
              this.loading = false
            })
        }
      },
    },
  }
</script>
