<template>
  <v-list two-line>
    <v-row>
      <v-col
        cols="12"
        sm="11"
        md="11"
        class="text-right"
      >
        <app-btn @click="addStage">Add stage</app-btn>
      </v-col>
    </v-row>
    <v-list-item-group
      v-if="stages.length"
      active-class="pink--text"
      multiple
    >
      <stage-item
        v-for="stage in stages"
        :key="stage.id"
        :item="stage"
      />
    </v-list-item-group>
    <template v-else>
      <v-list-item>
        <template v-slot:default="{ active }">
          <v-list-item-content>
            <v-container class="py-0">
              <v-row>
                No stages are added yet. Please, add stages in order to create a product
              </v-row>
            </v-container>
          </v-list-item-content>
        </template>
      </v-list-item>
    </template>
  </v-list>
</template>
<script>
  import { mapActions, mapGetters } from 'vuex'
  import StageItem from '@/components/Nomenclature/StageItem'

  export default {
    name: 'StagesTab',
    components: {
      StageItem,
    },
    props: {
      productId: {
        type: Number,
        required: true,
      },
    },
    computed: {
      ...mapGetters({
        stages: 'nomenclature/stages/getStages',
      }),
    },
    mounted () {
      if (this.productId) {
        this.loadStages({
          product_id: this.productId,
        })
      }
    },
    methods: {
      ...mapActions({
        loadStages: 'nomenclature/stages/loadStages',
        addLocalStage: 'nomenclature/stages/addLocalStage',
      }),
      addStage () {
        this.addLocalStage({
          product_id: this.productId,
        })
      },
    },
  }
</script>
