<template>
  <v-list-item>
    <template v-slot:default="{ active }">
      <v-list-item-content>
        <v-container class="py-0">
          <v-row>
            <v-col
              cols="12"
              sm="3"
              md="3"
            >
              <v-img :src="item.image_url" />
            </v-col>
            <v-col
              cols="12"
              sm="9"
              md="9"
            >
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    :value="item.stage_age_days"
                    type="number"
                    color="purple"
                    label="Stage Age Days"
                    @change="updateAge"
                  />
                </v-col>

                <v-col cols="12">
                  <v-file-input
                    label="Upload stage image"
                    truncate-length="35"
                    @change="updateImage"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-list-item-content>

      <v-list-item-action>
        <v-icon
          color="grey"
          @click="remove"
        >
          mdi-delete-forever
        </v-icon>
      </v-list-item-action>
    </template>
  </v-list-item>
</template>
<script>
  import { mapActions } from 'vuex'

  export default {
    name: 'StageItem',
    props: {
      item: {
        type: Object,
        required: true,
      },
    },
    data: () => ({
      image: null,
    }),
    methods: {
      ...mapActions({
        updateStage: 'nomenclature/stages/updateStage',
        uploadImage: 'nomenclature/stages/uploadImage',
        deleteLocalStage: 'nomenclature/stages/deleteLocalStage',
      }),
      updateImage (file) {
        const form = new FormData()
        form.append('image', file, file.name)
        form.append('product_id', this.item.product_id)
        form.append('stage_id', this.item.id)
        this.uploadImage(form)
          .then(result => result.json())
          .then(result => {
            this.updateStage({
              ...this.item,
              image_path: result.payload.image_path,
            })
          })
      },
      updateAge () {
        this.updateStage({
          ...this.item,
          image_path: this.image,
        })
      },
      remove () {
        this.deleteLocalStage(this.item.id)
      },
    },
  }
</script>
